var menuMobile = function()
{
	var open = $('body > header .actions a.open'),
		close = $('body > header .actions a.close'),
		menu = $('body > header nav'),
		altura = $('body > header nav .container').innerHeight();

	function menuOpen()
	{
		if($(window).width() > 680)
		{
			menu.stop().animate({height : altura},300);
		}
		else
		{
			menu.stop().animate({right : 0},300);
			$('body').css({'overflow':'hidden'});
		}
	}

	function menuClose()
	{
		if($(window).width() > 680)
		{
			menu.stop().animate({height : 0},300);
		}
		else
		{
			menu.stop().animate({right : -300},300);
			$('body').css({'overflow':'auto'});
		}
	}

	open.on('click',function(e){
		e.preventDefault();
		$(this).removeClass('activated');
		close.addClass('activated');
		menuOpen();
	});
	close.on('click',function(e){
		e.preventDefault();
		$(this).removeClass('activated');
		open.addClass('activated');
		menuClose();
	});

	function openSubmenu()
	{
		var altura = 0;

		$('body > header #menu-principal ul.sub-menu li').each(function(){
			altura += $(this).height() + 1;
		});

		$('body > header #menu-principal ul.sub-menu').stop(true,true).animate({height : altura},300);

	}

	function closeSubmenu()
	{
		var altura = 0;

		$('body > header #menu-principal ul.sub-menu').stop(true,true).animate({height : altura},300);

	}

	/* IDIOMA */

	$('body>header .idioma').on('click',function(){
		if($(this).hasClass('closed'))
		{
			$(this).removeClass('closed').addClass('opened').find('ul').show(300);
		}
		else
		{
			$(this).removeClass('opened').addClass('closed').find('ul').hide(300);
		}
	})

}

var bannerVideo = function()
{
	
	if($('#bannerHome').length > 0)
	{
		var bannerHome = $('#bannerHome');
		var tela = $(window).width();
		var videosite = bannerHome.find('#videosite');
		var covervideo = bannerHome.find('figure');

		if(tela > 992)
			videosite.find('video')[0].play();
		else
		{
			videosite.css({'display':'block'}).find('video')[0].play();
			covervideo.css({'display':'none'});
		}
	}
}

var slides = function()
{
	var swiper = new Swiper('#complemento .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        //effect: 'fade',
        loop: true,
        autoplay: 3000,
        autoplayDisableOnInteraction: false,
        speed: 2000,
        pagination: {
	        el: '#complemento .footer .navigation',
	        clickable: true,
	    },
    });
}

var propBox = function()
{
	var resizeBox = function(obj)
	{
		var nH = (315 * obj.width()) / 560;
		obj.css({'clear':'both'}).height(nH).stop().animate({opacity:1},300);
	}		
	$('iframe,.propBox').each(function(){
		resizeBox($(this));
	});
}

var sendMail = function(settings)
{
	
	/* OBJETOS */

	var path = settings.path,
		$form = $(settings.form),
		$lightbox = $(settings.lightbox),
		numRand = Math.floor(Math.random()*5),
		numRand2 = Math.floor(Math.random()*5),
		total = numRand + numRand2;

		$form.find('.captcha strong.item1').text(numRand);
		$form.find('.captcha strong.item2').text(numRand2);

	/* FUNÇÕES LIGHTBOX */

	function fechar()
	{
		$lightbox.find('a.fechar').click(function()
		{
			$lightbox.stop().animate({opacity:0},300,function(){
				$(this).css({'display':'none'});
				$(this).find('div').css({'opacity':0});
			});

			$('body').css({'overflow':'auto'});
			if($(window).width() > 540)
			{
				$form.find('.captcha input[type="submit"]').css({'display':'inline-block'});
			}
			else
			{
				$form.find('.captcha input[type="submit"]').css({'display':'block'});
			}
			$form.find('.captcha img.preloader').css({'display':'none'});

			return false;
		});
	}

	/* FUNÇÕES DE RESPOSTAS */

	function sucesso()
	{
		var html = '<div class="sucesso">';
				html += '<p>'+settings.sucesso+'</p>';
				html += '<a href="#" title="FECHAR" class="fechar">X</a>';
			html += '</div>';
		$lightbox.html(html).css({'display':'block'}).stop().animate({opacity:1},300,function(){
			$(this).find('div').stop().animate({opacity:1},300);
			$('body').css({'overflow':'hidden'});
			fechar();
		});
		$form.find('input[type="reset"]').trigger( "click" );
	}

	function erro()
	{
		var html = '<div class="erro">';
				html += '<p>'+settings.erro+'</p>';
				html += '<a href="#" title="FECHAR" class="fechar">X</a>';
			html += '</div>';
		$lightbox.html(html).css({'display':'block'}).stop().animate({opacity:1},300,function(){
			$(this).find('div').stop().animate({opacity:1},300);
			$('body').css({'overflow':'hidden'});
			fechar();
		});
	}

	/* DISPAROS DE FORMULÁRIOS */

	$form.on('submit',function()
	{
		if($(this).find('input[name="captcha"]').val() == total){
			$(this).find('.captcha input[type="submit"]').css({'display':'none'});
			if($(window).width() > 540)
			{
				$(this).find('.captcha img.preloader').css({'display':'inline-block'});
			}
			else
			{
				$(this).find('.captcha img.preloader').css({'display':'block'});
			}
			$.post(path,$(this).serialize(), function() {
				sucesso();
			});
		} else {
			erro();
		}
		return false;
	});

}

var accordeon = function()
{
	function doAction(obj)
	{
		var	allDT = $('#internas article.faq dt'),
			allDD = $('#internas article.faq dd'),
			dd = obj.next('dd'),
			altura = dd.find('div.content').innerHeight();

		if(obj.hasClass('closed'))
		{
			allDD.stop(true,true).animate({height : 0},300);
			dd.stop(true,true).animate({height : altura},300);
			allDT.removeClass('opened').addClass('closed');
			obj.removeClass('closed').addClass('opened');
		}
		else
		{
			allDD.stop(true,true).animate({height : 0},300);
			allDT.removeClass('opened').addClass('closed');
		}
	}
	
	$('#internas article.faq dt').on('click',function(e)
	{
		e.preventDefault();
		doAction($(this));
	});

}

var scrollPageHash = function()
{
	if(window.location.hash)
	{
		var hash = window.location.hash.split('#/')[1];
		$('html,body').animate({scrollTop: $("#"+hash).offset().top - 100},1000);
	}

	$('#internas article .ancoraNav h3 a').on('click',function(e){
		e.preventDefault();
		var target = $(this).attr('href').split('#/')[1];
		$('html,body').animate({scrollTop: $("#"+target).offset().top - 100},1000);
	});
}

$(function()
{

	$('.maskTel').mask("(99)99999-999?9");
	
	$( ".datepicker" ).datepicker({
		changeMonth : true,
		changeYear	: true,
		dateFormat 	: 'dd/mm/yy',
		yearRange	: "-100:+0"
	});

	if($('#formContato').length > 0)
	{
		sendMail({
			form 	 : '#formContato',
			path 	 : $('#lightbox').attr('data-path')+'/envia_contato.php',
			sucesso  : $('#lightbox').attr('data-sucesso'),
			erro	 : $('#lightbox').attr('data-error'),
			lightbox : '#lightbox'
		});
	}

	if($('#formAgendamento').length > 0)
	{
		sendMail({
			form 	 : '#formAgendamento',
			path 	 : $('#lightbox').attr('data-path')+'/envia_agendamento.php',
			sucesso  : $('#lightbox').attr('data-sucesso'),
			erro	 : $('#lightbox').attr('data-error'),
			lightbox : '#lightbox'
		});
	}

	slides();
	menuMobile();
	propBox();
	accordeon();

	$(window).resize(function()
	{
		bannerVideo();
		propBox();
	});

	$(window).on('load',function()
	{
		// accordeon();
		scrollPageHash();
		bannerVideo();
	});

});